package com.blkxltng.kobweb.components

import androidx.compose.runtime.Composable
import com.blkxltng.kobweb.util.Res
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.silk.components.graphics.Image
import org.jetbrains.compose.web.css.px

@Composable
fun RatingBar(modifier: Modifier = Modifier) {
    val numStars = 5
    Row(modifier = modifier) {
        repeat(numStars) {
            Image(
                modifier = Modifier
                    .margin(right = if (it != numStars-1) 2.px else 0.px)
                    .size(16.px),
                src = Res.Icon.star,
                desc = "Star Icon"
            )
        }
    }
}
