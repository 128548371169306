package com.blkxltng.kobweb.styles

import com.blkxltng.kobweb.models.Theme
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.focus
import com.varabyte.kobweb.silk.components.style.hover
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.px

val InputStyle by ComponentStyle {
    base {
        Modifier
            .border {
                color(Theme.LightGray.rgb)
                width(2.px)
                style(LineStyle.Solid)
            }
            .transition(CSSTransition(property = "border", 200.ms))
    }
    hover {
        Modifier
            .border {
                color(Theme.Primary.rgb)
                width(2.px)
                style(LineStyle.Solid)
            }
    }
    focus {
        Modifier
            .border {
                color(Theme.Primary.rgb)
                width(2.px)
                style(LineStyle.Solid)
            }
    }
}
