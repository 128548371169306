package com.blkxltng.kobweb.models

import androidx.compose.runtime.CompositionContextLocal
import org.jetbrains.compose.web.css.CSSUnit

enum class Experience(
    val number: String,
    val jobPosition: String,
    val description: String,
    val company: String,
    val location: String,
    val startDate: String,
    val endDate: String,
    val technologiesUsed: List<String> = listOf()
) {
    First(
        number = "01",
        jobPosition = "Android Developer",
        company = "Texta, Inc.",
        location = "New York, NY",
        description = "Debugged BLUR app, fixing errors to allow compilation and execution. Created a “Wheel of Fortune” style game using JavaScript, HTML, and CSS. Modified libraries to meet needs. Tested app functionality and added several features and fixes. Created simple unit and Espresso tests.",
        startDate = "December 2017",
        endDate = "January 2019",
        technologiesUsed = listOf("Retrofit", "Glide", "JLHTTP", "Webview", "RecyclerView", "JUnit", "Espresso", "HTML5", "Instabug")
    ),
    Second(
        number = "02",
        jobPosition = "Software Developer (Android)",
        company = "Bottle Rocket Studios",
        location = "Addison, TX (Remote)",
        description = "Worked on a hotel app and a flight booking app for a client. Created layouts based on comps designed by XD. Fixed a wide range of bugs reported by QA, testing to ensure a proper repair. Attended weekly company training sessions on new libraries and technology. Helped estimate sizes of various tasks and gave detailed reports on fixes for bugs and added features.",
        startDate = "August 2019",
        endDate = "March 2020",
        technologiesUsed = listOf("Data Binding", "Navigation Components", "Epoxy", "GraphQL", "Lifecycle Components", "Dagger", "RecyclerView")
    ),
    Third(
        number = "03",
        jobPosition = "Android Developer",
        company = "JPMorgan Chase & Co.",
        location = "New York, NY",
        description = "Worked with product and design to add new features and styles to the Chase and JPMorgan apps. Made sure iOS and Android apps matched as much as possible. Fixed several bugs reported by QA and others, to provide a smoother experience to customers.",
        startDate = "July 2020",
        endDate = "Now",
        technologiesUsed = listOf("Retrofit", "Dagger", "Mockk", "JUnit", "Jenkins", "Lifecycle Comp.", "Bluespec", "Dark Canary", "RecyclerView", "MVVM", "Coroutines")
    )
}