package com.blkxltng.kobweb.util

object Constants {
    const val WEBSITE = "https://www.blkxltng.com"
    const val SECTION_WIDTH = 1920
    const val FONT_FAMILY = "Roboto"

    const val LINK_TWITTER = "https://twitter.com/blkxltng"
    const val LINK_LINKEDIN = "https://www.linkedin.com/in/mauricegaynor/"
    const val LINK_GITHUB = "https://github.com/blkxltng"
    const val LINK_INSTAGRAM = "https://www.instagram.com/blkxltng/"

    const val MY_DESCRIPTION = "I'm an Android Developer who makes apps!"
    const val ABOUT_ME_TEXT = "This is a very long and random set of words that I am typing to describe who I am and what I do. " +
            "Feel free to ignore what any of it says. " +
            "I don't particularly care what it say myself if I'm being honest."

    const val LOREM_IPSUM_LONG =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    const val LOREM_IPSUM_SHORTEST =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    const val LOREM_IPSUM_SHORT =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
}

object Res {
    object Image {
        const val background = "images/background.svg"
        const val logo = "images/logo.svg"
        const val main = "images/main_image.png"
        const val about = "images/about_img.jpg"
        const val portfolio1 = "images/portfolio1.png"
        const val portfolio2 = "images/portfolio2.png"
        const val portfolio3 = "images/portfolio3.png"
        const val portfolio4 = "images/portfolio4.jpg"
        const val portfolio5 = "images/portfolio5.png"
        const val avatar1 = "images/avatar1.png"
        const val avatar2 = "images/avatar2.png"
        const val avatar3 = "images/avatar3.png"
        const val avatar4 = "images/avatar4.png"
        const val avatar5 = "images/avatar5.png"
        const val avatar6 = "images/avatar6.png"
    }

    object Icon {
        const val android = "images/icons/android_icon.svg"
        const val apple = "images/icons/apple_icon.svg"
        const val business = "images/icons/business_icon.svg"
        const val design = "images/icons/design_icon.svg"
        const val seo = "images/icons/seo_icon.svg"
        const val web = "images/icons/web_icon.svg"
        const val link = "images/icons/link_icon.svg"
        const val checkmark = "images/icons/checkmark_icon.svg"
        const val happy = "images/icons/happy_icon.svg"
        const val shield = "images/icons/shield_icon.svg"
        const val user = "images/icons/user_icon.svg"
        const val star = "images/icons/star_icon.svg"
    }
}
